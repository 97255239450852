import styled from 'styled-components';
import { colors } from '../../../theme/configs/baseVariable';
import inputThemes from '../../themes/input';
import { rgba } from '../../../theme/configs/utils';

export const DropdownStyled = styled('div')`
  position: relative;
  cursor: pointer;
  width: 100%;

  svg {
    position: absolute;
    top: 15px;
    right: 15px;
  }
`;

interface DropdownMenuType {
  theme: string;
}
export const DropdownMenu = styled('ol')<DropdownMenuType>`
  margin: 0;
  padding: 0;
  position: absolute;
  max-height: 200px;
  overflow-y: auto;
  top: 42px;
  left: 0px;
  right: 0px;

  ${props => `
    background-color: ${inputThemes[props.theme].backgroundColorActive};
    border: 4px solid ${inputThemes[props.theme].borderColorHover};
    // box-shadow: 0 1px 1px 0 ${
      inputThemes[props.theme].focusOutlineColor
    }, 0 0 0 2px ${inputThemes[props.theme].focusOutlineColor};
  `}
  border-top: none;
  border-radius: 0px 0px 5px 5px;

  z-index: 1;
  list-style-type: none;
`;

interface DropdownInputType {
  width?: string;
  height?: string;
  theme: string;
}

export const DropdownInput = styled('input')<DropdownInputType>`
  font-size: 16px;
  padding: 16px;
  // padding-right: 40px;
  height: 14px;

  border-radius: 5px;
  box-shadow: 0 1px 1px 0 rgba(${colors.black}, 0);
  ${props =>
    `
    height: ${props.height ? props.height : '14px'};
    width: ${props.width ? props.width : '-webkit-fill-available'};
    border: 1px solid ${inputThemes[props.theme].borderColor};
    background-color: ${inputThemes[props.theme].backgroundColor};

    &:not(:disabled):not(.disabled) {
      &:hover {
        background-color: #fff;
        border: 2px solid ${inputThemes[props.theme].borderColorHover};
        padding: 15px;
      }

      &:active,
      &.active,
      &:focus {
        outline: none;
        background-color: ${inputThemes[props.theme].backgroundColorActive};
        border: 4px solid ${inputThemes[props.theme].borderColorHover};
        border-radius: 5px 5px 0px 0px;
        border-bottom: none;
        padding: 13px;
        padding-right: 40px;
        //box-shadow: 0 1px 1px 0 ${
          inputThemes[props.theme].focusOutlineColor
        }, 0 0 0 2px ${inputThemes[props.theme].focusOutlineColor};
      }
    }
  `}
  &:disabled {
    cursor: not-allowed;
    background-color: ${rgba(colors.trueBlack, 0.05)};
    color: ${colors.disabled};
  }
`;

interface DropdownItemListType {
  disabled?: boolean;
}

export const DropdownItemList = styled('li')<DropdownItemListType>`
  position: relative;
  padding: 16px;
  outline: none;

  &:first-child {
    &:after {
      content: '';
      display: block;

      position: absolute;
      left: 0;
      right: 0;
      top: -1px;
      height: 1px;

      border-bottom: 1px solid ${colors.disabled};
      margin: 0 16px;
    }
  }

  &:active,
  &:focus,
  &:hover {
    background-color: ${rgba(colors.trueBlack, 0.05)};
  }

  &:before {
    content: '';
    display: block;

    position: absolute;
    left: 0;
    right: 0;
    top: -2px;
    height: 1px;

    border-bottom: 1px solid ${colors.disabled};
    margin: 0 16px;
  }
  ${props =>
    props.disabled &&
    `
      cursor: not-allowed;
      color: ${colors.disabled};
  `}
`;
