import styled from 'styled-components';

interface ModalContentProps {
  isHeigher?: boolean;
  heightVal?: string;
}

// position: ${(props): string => (props.isHeigher ? 'relative' : 'absolute')};

export const ModalContentStyle = styled('div')<ModalContentProps>`
  background-color: #ffffff;
  // border: 1px solid #888;
  position: absolute;
  bottom: 0;
  left: calc((100vw - 375px) / 2);
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  max-width: 375px !important;
  box-sizing: border-box;
  overflow-y: scroll;
  ${props =>
    props.isHeigher &&
    `height: ${props.heightVal};
  `}
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */

  &::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
  }

  .content {
    padding: 40px;
    text-align: center;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 374px) and (orientation: portrait) {
    max-width: calc(100vw) !important;
    left: 0;
  }
`;

export const ModalActionButtonStyle = styled('div')`
  display: flex;

  & .prixa-cancel-button {
    background-color: #eeeeee;
    color: #4c4f54;
  }
`;

export const ModalStyle = styled('div')`
  position: fixed;
  z-index: 10000000000;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  // overflow: auto;
  background-color: rgba(76, 79, 84, 0.6);

  ${ModalContentStyle}
`;
