import * as React from 'react';
import { CSSTransition } from 'react-transition-group';
import { ModalStyle, ModalContentStyle, ModalActionButtonStyle } from './style';
import '../theme/animate.min.css';

export interface ModalProps {
  show: boolean;
  content: React.ReactElement;
  buttons?: React.ReactElement;
  style?: React.CSSProperties;
  className?: string;
}

const Modal = (props: ModalProps): React.ReactElement => {
  const contentDiv = React.useRef<HTMLDivElement>(null);
  const [heigher, setHeigher] = React.useState(false);
  const [heightVal, setHeightVal] = React.useState('');

  React.useEffect(() => {
    if (contentDiv && contentDiv.current) {
      const hg = document.documentElement.clientHeight - 40;

      if (contentDiv.current.clientHeight >= hg) {
        setHeigher(true);
        setHeightVal(`${hg}px`);
      }
    }
  });

  return (
    <CSSTransition
      in={props.show}
      unmountOnExit
      timeout={100}
      className={{
        enter: 'animated',
        enterActive: 'fade-enter-active',
        exit: 'animated',
        exitActive: 'fade-exit-active',
      }}
    >
      <ModalStyle>
        <ModalContentStyle
          ref={contentDiv}
          isHeigher={heigher}
          heightVal={heightVal}
        >
          <div>
            <div
              style={props.style}
              className={
                props.className ? `content ${props.className}` : 'content'
              }
            >
              {props.content}
            </div>
            {props.buttons && (
              <ModalActionButtonStyle>{props.buttons}</ModalActionButtonStyle>
            )}
          </div>
        </ModalContentStyle>
      </ModalStyle>
    </CSSTransition>
  );
};

export default Modal;
