import * as React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import {
  AccordionStyled,
  AccordionTitleStyled,
  AccordionContentStyled,
  AccordionHeaderStyled,
} from './style';

export interface AccordionProps
  extends React.HtmlHTMLAttributes<HTMLImageElement> {
  /** Basic attr */
  id?: string;
  className?: string;
  danger?: boolean;
  success?: boolean;
  title: string;
  children?: any;
  disabled?: boolean;
  onClick?: (param?: any) => void;
  style?: React.CSSProperties;
  open?: boolean;
}

const Accordion: React.FC<AccordionProps> = ({
  title,
  style,
  open = false,
  ...props
}: AccordionProps) => {
  const [showContent, setShowContent] = React.useState<boolean>(open);

  const clickedAccrodion = () => {
    if (!props.disabled) setShowContent(!showContent);
  };

  React.useEffect(() => {
    setShowContent(open);
  }, [open, setShowContent]);

  React.useEffect(() => {
    props.onClick && props.onClick(showContent);
  }, [showContent]);

  return (
    <AccordionStyled
      style={style}
      danger={props.danger}
      success={props.success}
      disabled={props.disabled}
      active={showContent}
      className={props.className}
      onClick={clickedAccrodion}
      {...props}
    >
      <AccordionHeaderStyled>
        <AccordionTitleStyled>{title}</AccordionTitleStyled>
        <FontAwesomeIcon
          icon={showContent ? faChevronUp : faChevronDown}
          className="prixa-accordion-icon"
        />
      </AccordionHeaderStyled>
      <AccordionContentStyled>{props.children}</AccordionContentStyled>
    </AccordionStyled>
  );
};

Accordion.defaultProps = {
  title: '',
};

export default Accordion;
