import * as React from 'react';
import { Progress, ProgressInverse, ProgressWrapper } from './style';

export interface ProgressProps
  extends React.HtmlHTMLAttributes<HTMLDivElement> {
  /** Basic attr */
  className?: string;
  style?: React.CSSProperties;
  /** Custom attr */
  progressBarSize: number;
}

const ProgressBar: React.SFC<ProgressProps> = (props: ProgressProps) => {
  return (
    <ProgressWrapper
      className={props.className}
      progressBarSize={props.progressBarSize}
      style={props.style}
      {...props}
    >
      <Progress progressBarSize={props.progressBarSize}></Progress>
      <ProgressInverse
        progressBarSize={props.progressBarSize}
      ></ProgressInverse>
    </ProgressWrapper>
  );
};

ProgressBar.defaultProps = {
  progressBarSize: 0,
};

ProgressBar.displayName = 'ProgressBar';

export default ProgressBar;
