import * as React from 'react';
import { WrapperStyle } from './style';
import { Icon } from '../../src/icon';
import { Text } from '../../src/typography';
import { Sidesheet, openSideSheet } from '../../src/sidesheet';

export interface HeaderProps extends React.HtmlHTMLAttributes<HTMLElement> {
  /** Basic attr */
  sidesheetTitle?: string;
  title?: string;
  subtitle?: string;
  showSidesheetButton?: boolean;
  showFunctionButton?: boolean;
  sidesheetContent?: any;
  functionContent?: any;
  backOnClick?: any;
  titleOnClick?: any;
  sidesheetButtonIcon?: string;
  /** Custom attr */
  className?: string;
  classNameSidesheet?: string;
  style?: React.CSSProperties;
}

const Header: React.SFC<HeaderProps> = (props: HeaderProps) => {
  const [modal, setModal] = React.useState(false);
  const [{ title }, setHeader] = React.useState({ title: '', subtitle: '' });
  const imgHeader = `${process.env.REACT_APP_ASSET_URL}/prixa-header.png`;

  /* eslint-disable  */
    React.useEffect(() => {
        setHeader({ title: props.title || '', subtitle: props.subtitle || '' });
    }, [props.title]);
    /* eslint-enable  */

  return (
    <WrapperStyle
      title={props.title}
      subtitle={props.subtitle}
      showSidesheetButton={props.showSidesheetButton}
      showFunctionButton={props.showFunctionButton}
      className={props.className}
      style={props.style}
    >
      <div className="prixa-header">
        <div
          className="prixa-menuback"
          onClick={
            props.backOnClick
              ? props.backOnClick
              : () => {
                  window.history.back();
                }
          }
        >
          <Icon type="faChevronLeft" style={{ width: 16 }} />
        </div>
        <div className="prixa-caption">
          <Text scale="headerTitle">
            {(() => {
              if (title) {
                return title;
              } else {
                return (
                  <img
                    loading="lazy"
                    width="50px"
                    alt="logo"
                    className="prixa-header-image"
                    src={imgHeader}
                    style={{ cursor: 'pointer' }}
                    onClick={
                      props.titleOnClick
                        ? props.titleOnClick
                        : () => {
                            window.location.href = '/';
                          }
                    }
                  />
                );
              }
            })()}
          </Text>
          {/* {(() => {
                if (subtitle) {
                    return <Text scale="headerSubtitle">{subtitle}</Text>;
                }
                })()} */}
        </div>
        <div className="prixa-menubar">
          {props.showSidesheetButton && (
            <span
              onClick={() => {
                setModal(true);
                openSideSheet();
              }}
            >
              <Icon
                color="primary"
                type={props.sidesheetButtonIcon || 'faBars'}
                width="16px"
              />
            </span>
          )}

          {props.showFunctionButton && (
            <React.Fragment>{props.functionContent}</React.Fragment>
          )}
        </div>
        <Sidesheet
          setModal={setModal}
          show={modal}
          title={props.sidesheetTitle}
          className={`prixa ${props.classNameSidesheet || ''}`}
          content={props.sidesheetContent}
        ></Sidesheet>
      </div>
      );
    </WrapperStyle>
  );
};

Header.defaultProps = {
  sidesheetTitle: 'Menu',
};

export default Header;
