export * from './button';
export * from './loading';
export * from './icon';
export * from './box';
export * from './form';
export * from './toast';
export * from './image';
export * from './card';
export * from './accordion';
export * from './sidesheet';
export * from './searchBar';
export * from './modal';
export * from './header';

// eslint-disable-next-line
import './theme/style.css';
import { default as Typography } from './typography';
import { PrixaStyle } from './theme';

const Paragraph = Typography.Paragraph;
const List = Typography.List;
const Text = Typography.Text;
const Link = Typography.Link;

export const Foundation = {
  Typography,
  PrixaStyle,
};

export { PrixaStyle, Typography, Paragraph, List, Text, Link };
