import React from 'react';
import { OptionsPrixa, InputOptionsPrixa, DivOptionsPrixa } from './style';

export interface OptionsProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  data: {
    id: string | number;
    nameIndo?: string;
    name: string;
  };
  setData: any;
  selected: any;
  style?: React.CSSProperties;
  className?: string;
  disable?: boolean;
}

const OptionsButton = (props: {
  data: any;
  setData: any;
  selected: any;
  style?: React.CSSProperties;
  className?: string;
  disable?: boolean;
}): React.ReactElement => {
  return (
    <React.Fragment>
      {props &&
        props.data &&
        props.data.map((item: any) => {
          return (
            <OptionsPrixa key={item.id}>
              <InputOptionsPrixa
                type="radio"
                name="radio-btn"
                style={props.style}
                className={props.className}
                checked={
                  JSON.stringify(props.selected) === JSON.stringify(item)
                }
                value={item.id}
                onChange={() => {
                  props.setData(item);
                }}
                disabled={props.disable}
                {...props}
              />
              <DivOptionsPrixa>{item.nameIndo || item.name}</DivOptionsPrixa>
            </OptionsPrixa>
          );
        })}
    </React.Fragment>
  );
};

export default OptionsButton;
