import * as React from 'react';
import { BoxWithStyle } from './style';

export interface BoxProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  /** Basic attr */
  children?: any;
  id?: string;
  className?: string;
  style?: React.CSSProperties;
  /** Custom attr */
  variant?: string;
  bordered?: boolean;
}

const Box: React.FunctionComponent<BoxProps> = (
  props: BoxProps
): React.ReactElement => (
  <BoxWithStyle
    className={props.className}
    variant={props.variant}
    bordered={props.bordered}
    style={props.style}
    {...props}
  >
    {props.children}
  </BoxWithStyle>
);

Box.displayName = 'Box';

export default Box;
