import styled, { css } from 'styled-components';
import { colors } from '../theme/configs/baseVariable';
import { HeaderProps } from './Header';

const wrapperStyling = (props: HeaderProps) => css`
  .prixa {
    &-header {
      padding: 12px 0px;
      height: 40px;
      border-bottom: 1px solid ${colors.borderColor};
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: ${colors.white};
      z-index: 2;
      position: fixed;
      top: 0;
      width: 100%;

      &-image {
        width: 50.11px;
        height: 24px;
        margin-top: 3px;
      }
    }

    &-progress-bar {
      top: 65px;
      z-index: 2;
      position: fixed;
    }

    &-caption {
      display: flex;
      flex-direction: column;
      text-align: center;
      font-weight: bold;
    }

    &-menubar {
      position: absolute;
      right: 0;
      margin-right: 20px;
      cursor: pointer;
    }

    &-menuback {
      position: absolute;
      left: 0;
      margin-left: 20px;
      cursor: pointer;

      svg {
        color: #0146ab;
        font-size: 18px;
      }
    }
  }
`;

export const WrapperStyle = styled('div')<HeaderProps>`
  ${wrapperStyling}
`;
