import React, { useState, KeyboardEvent, useRef, useEffect } from 'react';
import { NAVIGATION_KEYS, KEY_CODES } from './constant';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import {
  DropdownStyled,
  DropdownMenu,
  DropdownInput,
  DropdownItemList,
} from './styles';

export interface DropdownItem {
  text?: string;
  value?: any;
  disabled?: boolean;
  focused?: boolean;
  onClick?: (param?: any) => void;
}

const DropdownSearchItem = ({
  focused,
  text,
  disabled,
  onClick,
}: DropdownItem) => {
  const itemRef = useRef<any>(null);
  useEffect(() => {
    if (focused && itemRef.current) {
      itemRef.current.focus();
    }
  }, [focused]);

  return (
    <DropdownItemList
      ref={itemRef}
      role="option"
      tabIndex={0}
      disabled={disabled}
      onClick={onClick}
      onKeyDown={onClick}
    >
      {text}
    </DropdownItemList>
  );
};

interface DropdownType {
  options: Array<any>;
  disabled?: boolean;
  placeholder?: string;
  width?: string;
  height?: string;
  onSelect?: (item: DropdownItem) => void;
  error?: boolean;
  validate?: boolean;
  noResultMessage?: string;
  value?: string;
  styleContainer?: React.CSSProperties;
  styleInput?: React.CSSProperties;
  styleIcon?: React.CSSProperties;
  styleItems?: React.CSSProperties;
}

export const DropdownSearch = ({
  options,
  disabled,
  onSelect,
  width,
  height,
  error,
  validate,
  noResultMessage,
  placeholder,
  value,
  styleContainer,
  styleInput,
  styleIcon,
  styleItems,
  ...props
}: DropdownType) => {
  const dropdownRef = useRef<any>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [focusedIndex, setFocusedIndex] = useState(-1);
  const [optionsVal, setOptionsVal] = useState(options);
  const [inputValue, setinputValue] = useState<string>(
    value !== undefined ? value : ''
  );
  let timeOutId: any = null;
  noResultMessage = noResultMessage ? noResultMessage : 'Tidak ada data.';

  useEffect(() => {
    setOptionsVal(options);
    setinputValue('');
  }, [options]);

  useEffect(() => {
    if (value !== undefined) setinputValue(value);
  }, [value]);

  const openDropdownMenu = () => {
    setIsOpen(true);
    // dropdownRef.current.blur();
  };

  const onOptionClicked = ({ nativeEvent }: any) => {
    if (nativeEvent instanceof KeyboardEvent) {
      if (nativeEvent.keyCode !== KEY_CODES.ENTER) return;
      nativeEvent.preventDefault();
    }

    if (nativeEvent.target) {
      const newSelectedOption = (nativeEvent.target as HTMLButtonElement)
        .innerText;

      if (onSelect !== undefined) {
        onSelect(
          options.find(
            (ops: any) =>
              ops.text.toUpperCase().indexOf(newSelectedOption.toUpperCase()) >
              -1
          )
        );
      }
      setinputValue(newSelectedOption);
      setIsOpen(false);
      dropdownRef.current.blur();
    }
  };

  // We close the popover on the next tick by using setTimeout.
  // This is necessary because we need to first check if
  // another child of the element has received focus as
  // the blur event fires prior to the new focus event.
  const onBlurHandler = () => {
    // const selectCloser = options.find(
    //   (ops: any) =>
    //     ops.text.toUpperCase().indexOf(inputValue.toUpperCase()) > -1
    // );
    // if (selectCloser) setinputValue(selectCloser.text);
    // else if (options.length > 0) setinputValue(options[0].text);

    timeOutId = setTimeout(() => {
      setIsOpen(false);
    });
  };

  // If a child receives focus, do not close the popover.
  const onFocusHandler = () => {
    clearTimeout(timeOutId);
  };

  // React assists us by bubbling the blur and
  // focus events to the parent.

  const onNavigation = (keyCode: number) => {
    switch (keyCode) {
      case KEY_CODES.UP_ARROW:
        setFocusedIndex(prev => {
          if (prev === -1) {
            setinputValue(optionsVal[0].text);
            return 0;
          }
          if (prev === 0) {
            setinputValue(optionsVal[optionsVal.length - 1].text);
            return optionsVal.length - 1;
          }
          setinputValue(optionsVal[prev - 1].text);
          return prev - 1;
        });
        break;
      case KEY_CODES.DOWN_ARROW:
        setFocusedIndex(prev => {
          setinputValue(optionsVal[(prev + 1) % optionsVal.length].text);
          return (prev + 1) % optionsVal.length;
        });
        break;
      case KEY_CODES.ESCAPE:
        setIsOpen(false);
        break;
      default:
        break;
    }
  };

  const onKeyDown = ({ nativeEvent }: KeyboardEvent) => {
    const { keyCode } = nativeEvent;

    if (NAVIGATION_KEYS.indexOf(keyCode) !== -1) {
      nativeEvent.preventDefault();
      onNavigation(keyCode);
    } else if (keyCode === KEY_CODES.TAB) {
      setIsOpen(false);
    }
  };

  const searchByInput = (e: any) => {
    const search = e.target.value;
    setinputValue(search);
    setIsOpen(true);
    setOptionsVal(
      options.filter(
        (ops: any) => ops.text.toUpperCase().indexOf(search.toUpperCase()) > -1
      )
    );
  };

  let theme = 'defaultTheme';
  if (error) theme = 'error';
  if (validate) theme = 'validate';

  return (
    <DropdownStyled
      onBlur={onBlurHandler}
      onFocus={onFocusHandler}
      onKeyDown={onKeyDown}
      style={styleContainer}
    >
      <DropdownInput
        style={styleInput}
        className={isOpen ? 'active' : ''}
        width={width}
        height={height}
        theme={theme}
        onFocus={openDropdownMenu}
        onClick={openDropdownMenu}
        aria-haspopup="true"
        aria-expanded={isOpen}
        disabled={disabled}
        ref={dropdownRef}
        value={inputValue}
        onChange={searchByInput}
        placeholder={placeholder}
        {...props}
      />
      <FontAwesomeIcon icon={faChevronDown} style={styleIcon} />
      {isOpen && (
        <DropdownMenu theme={theme} style={styleItems}>
          {optionsVal.length > 0 ? (
            optionsVal.map(({ value, text }: DropdownItem, i) => (
              <DropdownSearchItem
                key={value}
                text={text}
                focused={focusedIndex === i}
                onClick={onOptionClicked}
              />
            ))
          ) : (
            <DropdownSearchItem text={noResultMessage} disabled={true} />
          )}
        </DropdownMenu>
      )}
    </DropdownStyled>
  );
};
export default DropdownSearch;
