import * as React from 'react';

import { styledWrapper as styled } from '../../theme/configs/baseComponents';
import { TypeScale } from '../../theme';

import { fontDimensions } from '../configs/fontDimensions';
import Typography, { TypographyProps } from './Typography';

export interface TextProps extends TypographyProps {
  /** Basic Attr */
  children?: any;
  className?: string;
  style?: React.CSSProperties;
  /** Custom Attr */
  scale?: TypeScale;
  fontWeight?: number | undefined;
  fontSize?: string | undefined;
  color?: string | undefined;
  lineHeight?: string | undefined;
  fontStyle?: string | undefined;
}

const StyledText = styled(Typography)<TextProps>`
  ${props => props.scale === 'body' && 'font-weight: 400'}
`;

const Text: React.FunctionComponent<TextProps> = (
  props: TextProps
): React.ReactElement => (
  <StyledText
    {...fontDimensions(
      props.scale || 'content',
      undefined,
      undefined,
      props.fontWeight || undefined,
      props.fontSize || undefined,
      props.color || undefined,
      props.lineHeight || undefined,
      props.fontStyle || undefined
    )}
    style={props.style}
    className={props.className}
    {...props}
  >
    {props.children}
  </StyledText>
);

Text.defaultProps = {
  scale: 'content',
};

Text.displayName = 'Text';

export default Text;
