import * as React from 'react';
import { Icon } from '../../src/icon';
import { Text } from '../../src/typography';
import { CSSTransition } from 'react-transition-group';
import {
  SidesheetStyle,
  SideSheetHeaderStyle,
  SidesheetCaptionStyle,
  SidesheetImageCaptionStyle,
  SidesheetMenubarStyle,
} from './style';
import '../theme/animate.min.css';

const imgHeader = `${process.env.REACT_APP_ASSET_URL}/prixa-header.png`;

export interface SideSheetProps {
  title?: string;
  subtitle?: string;
  setModal: (modal: boolean) => void;
  show: boolean;
  content: React.ReactElement;
  className?: string;
  style?: React.CSSProperties;
}

const Sidesheet = (props: SideSheetProps): React.ReactElement => {
  return (
    <CSSTransition
      in={props.show}
      unmountOnExit
      timeout={1000}
      classNames={{
        enter: 'animated',
        enterActive: 'slideInRight faster',
        exit: 'animated',
        exitActive: 'slideOutRight faster',
      }}
    >
      <SidesheetStyle {...props}>
        <SideSheetHeaderStyle>
          <SidesheetCaptionStyle>
            <Text scale="headerTitle">
              {((): string | React.ReactElement => {
                if (props.title) {
                  return props.title;
                } else {
                  return (
                    <SidesheetImageCaptionStyle alt="logo" src={imgHeader} />
                  );
                }
              })()}
            </Text>
            {/* {(() => {
              if (props.subtitle) {
                return <Text scale="headerSubtitle">{props.subtitle}</Text>;
              }
            })()} */}
          </SidesheetCaptionStyle>
          <SidesheetMenubarStyle>
            <span
              onClick={(): void => {
                props.setModal(false);
                const scrollY = document.body.style.top;
                document.body.style.position = '';
                document.body.style.top = '';
                window.scrollTo(0, parseInt(scrollY || '0') * -1);
              }}
            >
              <Icon color="dark" type="faTimes" width="16px" />
            </span>
          </SidesheetMenubarStyle>
        </SideSheetHeaderStyle>
        <div className={`prixa ${props.className || ''}`} style={props.style}>
          {props.content}
        </div>
      </SidesheetStyle>
    </CSSTransition>
  );
};

const openSideSheet = (): void => {
  document.body.style.top = `-${window.scrollY}px`;
  document.body.style.position = 'fixed';
  document.body.style.width = '100%';
};

const closeSideSheet: any = () => {
  document.body.style.top = `-${window.scrollY}px`;
  document.body.style.position = '';
  document.body.style.top = '';
};

export default Sidesheet;
export { openSideSheet, closeSideSheet };
