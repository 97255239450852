import * as React from 'react';
import { FlatWrapper } from './style';

export interface FlatLoadingProps
  extends React.HtmlHTMLAttributes<HTMLElement> {
  /** Basic attr */
  className?: string;
  style?: React.CSSProperties;
  /** Custom attr */
  size?: string;
  color?: string;
}

const FlatLoading: React.SFC<FlatLoadingProps> = (props: FlatLoadingProps) => {
  return (
    <FlatWrapper
      size={props.size}
      color={props.color}
      style={props.style}
      className={props.className}
      {...props}
    >
      <span className="dot"></span>
      <div className="dots">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </FlatWrapper>
  );
};

FlatLoading.defaultProps = {
  color: 'primary',
  size: '100px',
};

FlatLoading.displayName = 'FlatLoading';

export default FlatLoading;
